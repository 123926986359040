<template>
  <div class="index-index-container">
    <el-form class="index-index-form">
      <el-col class="form-name">Activity Webh5 Login</el-col>
      <el-col class="form-input">
        <el-form-item>
          <el-input type="text" placeholder="please input your username" suffix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="password" placeholder="please input your password" suffix-icon="el-icon-lock"></el-input>
        </el-form-item>
      </el-col>
      <el-col class="form-submit">
        <el-form-item>
          <el-button class="button" type="primary">Login</el-button>
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'remarkLogin',
  data: () => {
    return {
      popList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },
  computed: {
    getRandomPopover: (list) => {
      return list.map(item => {
        return item
      })
    }
  }
}
</script>

<style lang="less" scoped>
.index-index-container{
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #8cc3fc, #409EFF);
  width: 100%;
  height: 100%;
  overflow: hidden;
  .index-index-form{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    min-width: 320px;
    .form-name{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
      font-size: 24px;
    }
    .form-input{
      margin-bottom: 40px;
    }
    .form-submit{
      .button{
        width: 100%;
      }
    }
  }
}
</style>